import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import data from "./data.json";
import firebase from "./firebase.js";
import moment from "moment";
import "url-search-params-polyfill";
import { BrowserRouter, Switch, Route, Link } from "react-router-dom";
import mobileAnimation from "./img/mobile-animation.gif";
import chartIfYouInvestPNG from "./img/chart-if-you-invest-v2@4x.png";
import chartIfYouInvestSVG from "./img/chart-if-you-invest-v2.svg";
import chartAddingAnotherPNG from "./img/chart-adding-another-v2@4x.png";
import chartAddingAnotherSVG from "./img/chart-adding-another-v2.svg";
import chart12kPNG from "./img/chart-12k-v2@4x.png";
import chart12kSVG from "./img/chart-12k-v2.svg";
import chart12kBlackPNG from "./img/chart-12k-v2--black@4x.png";
import chart12kBlackSVG from "./img/chart-12k-v2--black.svg";
import chart24kBlackPNG from "./img/chart-24k-v2--black@4x.png";
import chart24kBlackSVG from "./img/chart-24k-v2--black.svg";
import chart42kPNG from "./img/chart-42k-v2@4x.png";
import chart42kSVG from "./img/chart-42k-v2.svg";
import chart54kPNG from "./img/chart-54k-v2@4x.png";
import chart54kSVG from "./img/chart-54k-v2.svg";
import chart54kBlackPNG from "./img/chart-54k-v2--black@4x.png";
import chart54kBlackSVG from "./img/chart-54k-v2--black.svg";
import chart81kPNG from "./img/chart-81k-v2@4x.png";
import chart81kSVG from "./img/chart-81k-v2.svg";
import chart105kPNG from "./img/chart-105k-v2@4x.png";
import chart105kSVG from "./img/chart-105k-v2.svg";

const clickMap = {
  "save-1-0": "Save 1 Node A",
  "save-1-1": "Save 1 Node B",
  "save-1-2": "Save 1 Node C",
  "save-2-0": "Save 2 Node A",
  "save-3-0": "Save 3 Node A",
  "save-4-0": "Save 4 Node A",
  "invest-1-0": "Invest 1 Node A",
  "invest-1-1": "Invest 1 Node B",
  "invest-1-2": "Invest 1 Node C",
  "invest-2-0": "Invest 2 Node A",
  "invest-3-0": "Invest 3 Node A"
};

class App extends React.Component {
  state = {
    isOpen: false,
    marketingId: null,
    databaseKey: null,
    lastClick: null,
    currentPage: null,
    currentPath: null,
    clickstream: [],
    progress: {
      save: false,
      invest: false,
      save1: [],
      save2: [],
      save3: [],
      save4: [],
      invest1: [],
      invest2: [],
      invest3: []
    }
  };

  handleClick = e => {
    if (e.target.classList[0] === "node_trigger") {
      return null;
    } else {
      this.setState({
        isOpen: false
      });
    }
  };

  openNode = i => {
    this.setState({
      isOpen: i
    });
  };

  updateNodeProgress = (screen, update) => {
    const screenKey = screen.replace(/-/g, "");
    this.setState(state => ({
      progress: {
        ...state.progress,
        [screenKey]: [...state.progress[screenKey], update]
      }
    }));
  };

  // Called at initial app load
  // If the user has a marketing ID appended to their URL, it gets logged in state
  // Otherwise, if no ID is found, it prints an error message to state

  setMarketingId = id => {
    if (id) {
      this.setState({
        marketingId: id
      });
    } else {
      this.setState({
        marketingId: "No ID found"
      });
    }
  };

  // Creates a new database key for this quiz session
  // All progress is written to this key as the user completes the quiz

  setDatabaseRef = () => {
    const newRef = firebase
      .database()
      .ref()
      .push();
    this.setState({
      databaseKey: newRef.key
    });
  };

  // Sets the user's chosen scenario to true in the progress object
  setScenario = scenario => {
    this.setState(state => ({
      progress: {
        ...state.progress,
        [scenario]: true
      }
    }));
  };

  updateClickstream = (name, clicked, cta = false) => {
    let lastClick = clicked ? clicked : this.state.lastClick;
    if (lastClick && clickMap[lastClick]) {
      lastClick = clickMap[lastClick];
    }

    const timeStr = moment().format("YYYY-MM-DDTHH:mm:ss");

    let clickKey = "choice";
    if (!lastClick || lastClick.startsWith("http") || cta === true) {
      clickKey = "CTA";
    }
    let click = {
      [clickKey]: lastClick || "Article page: Play Now",
      time: timeStr
    };

    let destination;
    if (name) {
      destination = {
        page: name,
        time: timeStr
      };
    }

    this.setState(({ clickstream }) => {
      let newClickstream;
      if (destination) {
        newClickstream = [...clickstream, click, destination];
      } else {
        newClickstream = [...clickstream, click];
      }
      return {
        clickstream: newClickstream,
        currentPage: name,
        clickUrl: window.location.href
      };
    });
  };

  updateLastClick = name => {
    let clickName = clickMap[name] ? clickMap[name] : name;
    this.setState({
      lastClick: clickName,
      currentPath: window.location.href
    });
  };

  // Pushes progress to firebase every time the state of App component updates

  pushProgress(reference) {
    if (this.state.progress) {
      const progressObject = {
        marketingId: this.state.marketingId,
        clickstream: this.state.clickstream,
        otherProgress: this.state.progress
      };
      firebase
        .database()
        .ref(reference)
        .update(progressObject);
    }
  }

  componentDidMount() {
    let urlParams = new URLSearchParams(window.location.search);
    this.setMarketingId(urlParams.get("mpid"));
    this.setDatabaseRef();
    document.title = "Thinking Ahead";
  }

  componentDidUpdate() {
    this.pushProgress("/thinkingAhead/" + this.state.databaseKey);
  }

  render() {
    return (
      <BrowserRouter basename="/quiz">
        <Screen
          handleClick={this.handleClick}
          openNode={this.openNode}
          nodeIsOpen={this.state.isOpen}
          setScenario={this.setScenario}
          updateNodeProgress={this.updateNodeProgress}
          updateClickstream={this.updateClickstream}
          updateLastClick={this.updateLastClick}
        />
      </BrowserRouter>
    );
  }
}

class Screen extends React.Component {
  render() {
    const handleClick = this.props.handleClick;
    const openNode = this.props.openNode;
    const nodeIsOpen = this.props.nodeIsOpen;
    const setScenario = this.props.setScenario;
    const updateNodeProgress = this.props.updateNodeProgress;
    const updateClickstream = this.props.updateClickstream;
    const updateLastClick = this.props.updateLastClick;
    let wrapper = function(element, i) {
      switch (element.properties.type) {
        case "landing":
          return (
            <Route
              path="/"
              exact
              key={i}
              render={() => (
                <Landing
                  currentScreen={i}
                  setScenario={setScenario}
                  updateLastClick={updateLastClick}
                  updateClickstream={updateClickstream}
                />
              )}
            />
          );
        case "graph":
          const graphPath = "/" + element.properties.name;
          return (
            <Route
              path={graphPath}
              key={i}
              render={() => (
                <Graph
                  currentScreen={i}
                  handleClick={handleClick}
                  openNode={openNode}
                  nodeIsOpen={nodeIsOpen}
                  updateNodeProgress={updateNodeProgress}
                  updateClickstream={updateClickstream}
                  updateLastClick={updateLastClick}
                />
              )}
            />
          );
        case "result":
          const resultPath = "/" + element.properties.name;
          return (
            <Route
              path={resultPath}
              key={i}
              render={() => (
                <Result
                  currentScreen={i}
                  updateClickstream={updateClickstream}
                  updateLastClick={updateLastClick}
                />
              )}
            />
          );
        case "contact":
          return (
            <Route
              path="/contact"
              key={i}
              render={() => (
                <Contact
                  currentScreen={i}
                  updateClickstream={updateClickstream}
                  updateLastClick={updateLastClick}
                />
              )}
            />
          );
        case "legal":
          return (
            <Route
              path="/legal"
              key={i}
              render={() => (
                <Legal
                  currentScreen={i}
                  updateClickstream={updateClickstream}
                  updateLastClick={updateLastClick}
                />
              )}
            />
          );
        default:
          break;
      }
    };
    return (
      <Switch>
        {data.map(wrapper)}
        <Route
          render={() => (
            <Landing currentScreen={0} updateClickstream={updateClickstream} />
          )}
        />
      </Switch>
    );
  }
}

class Landing extends React.Component {
  componentDidMount() {
    this.props.updateClickstream("Landing");
  }
  render() {
    const currentScreen = this.props.currentScreen;
    const setScenario = this.props.setScenario;
    const updateLastClick = this.props.updateLastClick;
    return (
      <div className="screen">
        <div className="screen_content">
          <GlobalHeader updateClickstream={this.props.updateClickstream} />
          <div className="content_wrapper content_wrapper--landing">
            <MainHeader currentScreen={currentScreen} />
            <BodyCopy currentScreen={currentScreen} />
            <LandingButtons
              currentScreen={currentScreen}
              setScenario={setScenario}
              updateLastClick={updateLastClick}
            />
            <div className="ribbon_wrapper">
              <div className="sprite-ribbon" />
            </div>
          </div>
        </div>
        <DisplaySizeFallback updateClickstream={this.props.updateClickstream} />
      </div>
    );
  }
}

class Graph extends React.Component {
  componentDidMount() {
    const pageName = data[this.props.currentScreen].title.join(" ");
    this.props.updateClickstream(pageName);
  }
  render() {
    const currentScreen = this.props.currentScreen;
    const handleClick = this.props.handleClick;
    const openNode = this.props.openNode;
    const nodeIsOpen = this.props.nodeIsOpen;
    const updateNodeProgress = this.props.updateNodeProgress;
    const updateClickstream = this.props.updateClickstream;
    const updateLastClick = this.props.updateLastClick;
    return (
      <div
        className="screen"
        onClick={e => {
          handleClick(e);
        }}
      >
        <div className="screen_content">
          <GlobalHeader updateClickstream={updateClickstream} />
          <div className="content_wrapper">
            <Header currentScreen={currentScreen} />
            <BodyCopy currentScreen={currentScreen} />
            <GraphContent
              currentScreen={currentScreen}
              openNode={openNode}
              nodeIsOpen={nodeIsOpen}
              updateNodeProgress={updateNodeProgress}
              updateClickstream={updateClickstream}
              updateLastClick={updateLastClick}
            />
          </div>
          <AdvanceButton
            currentScreen={currentScreen}
            updateLastClick={updateLastClick}
          />
        </div>
        <DisplaySizeFallback updateClickstream={updateClickstream} />
      </div>
    );
  }
}

class Result extends React.Component {
  componentDidMount() {
    const pageName = data[this.props.currentScreen].title.join(" ");
    this.props.updateClickstream(pageName);
  }
  render() {
    const currentScreen = this.props.currentScreen;
    const updateLastClick = this.props.updateLastClick;
    const updateClickstream = this.props.updateClickstream;
    return (
      <div className="screen">
        <div className="screen_content">
          <GlobalHeader updateClickstream={updateClickstream} />
          <div className="content_wrapper">
            <MainHeader currentScreen={currentScreen} />
            <BodyCopy currentScreen={currentScreen} />
          </div>
          <ResultButtons
            currentScreen={currentScreen}
            updateLastClick={updateLastClick}
            updateClickstream={updateClickstream}
          />
        </div>
        <DisplaySizeFallback updateClickstream={updateClickstream} />
      </div>
    );
  }
}

class Contact extends React.Component {
  componentDidMount() {
    const pageName = data[this.props.currentScreen].title.join(" ");
    this.props.updateClickstream(pageName);
  }
  render() {
    const currentScreen = this.props.currentScreen;
    const updateLastClick = this.props.updateLastClick;
    const updateClickstream = this.props.updateClickstream;
    let wrapper = (element, i) => {
      return (
        <p key={i} className="contact_disclaimer_copy">
          {element}
        </p>
      );
    };
    return (
      <div className="screen">
        <div className="screen_content">
          <GlobalHeader updateClickstream={updateClickstream} />
          <div className="content_wrapper">
            <Header currentScreen={currentScreen} />
            <BodyCopy currentScreen={currentScreen} />
          </div>
          <ContactButtons
            currentScreen={currentScreen}
            updateLastClick={updateLastClick}
            updateClickstream={updateClickstream}
          />
          <div className="contact_disclaimer">
            {data[currentScreen].disclaimer.map(wrapper)}
          </div>
          <AdvanceButton
            currentScreen={currentScreen}
            updateLastClick={updateLastClick}
          />
        </div>
        <DisplaySizeFallback updateClickstream={updateClickstream} />
      </div>
    );
  }
}

class Legal extends React.Component {
  componentDidMount() {
    const pageName = data[this.props.currentScreen].properties.name;
    this.props.updateClickstream(pageName);
  }
  render() {
    const currentScreen = this.props.currentScreen;
    const updateLastClick = this.props.updateLastClick;
    return (
      <div className="screen screen--legal">
        <div className="screen_content">
          <GlobalHeader updateClickstream={this.props.updateClickstream} />
          <div className="legal_copy_wrapper">
            {data[currentScreen].copy.map((copy, i) => (
              <p key={i} className="option_copy">
                {copy}
              </p>
            ))}
          </div>
          <AdvanceButton
            currentScreen={currentScreen}
            updateLastClick={updateLastClick}
          />
        </div>
        <DisplaySizeFallback updateClickstream={this.props.updateClickstream} />
      </div>
    );
  }
}

class GlobalHeader extends React.Component {
  render() {
    return (
      <div className="global_header">
        <a
          href="http://ubs.com"
          className="sprite-close"
          onClick={() => {
            this.props.updateClickstream("http://ubs.com", "Close Icon");
          }}
        >
          close
        </a>
        <span className="sprite-logo">UBS</span>
      </div>
    );
  }
}

class MainHeader extends React.Component {
  render() {
    const currentScreen = this.props.currentScreen;
    let wrapper = function(element, i) {
      if (data[currentScreen].properties.name === "landing") {
        return (
          <div className="header_wrapper">
            <h1 className="main_header">
              {data[currentScreen].title[0]}{" "}
              <span className="red">{data[currentScreen].title[1]}</span>
            </h1>
            <h2 className="main_subheader">{data[currentScreen].title[2]}</h2>
          </div>
        );
      } else {
        return (
          <div className="header_wrapper">
            <h1 className="main_header">
              {data[currentScreen].title[0]}{" "}
              <span className="red">{data[currentScreen].title[1]}</span>
            </h1>
          </div>
        );
      }
    };
    return wrapper();
  }
}

class Header extends React.Component {
  render() {
    const currentScreen = this.props.currentScreen;
    return (
      <div className="header_wrapper">
        {data[currentScreen].title.map((title, i) => (
          <h1 className="header" key={i}>
            {title}
          </h1>
        ))}
      </div>
    );
  }
}

class BodyCopy extends React.Component {
  render() {
    const currentScreen = this.props.currentScreen;
    if (
      data[currentScreen].properties.name === "landing" ||
      data[currentScreen].properties.type === "result"
    ) {
      return (
        <div className="body_copy_wrapper landing_copy_wrapper">
          {data[currentScreen].copy.map((copy, i) => (
            <p className="body_copy" key={i}>
              {copy}
            </p>
          ))}
        </div>
      );
    } else {
      return (
        <div className="body_copy_wrapper">
          {data[currentScreen].copy.map((copy, i) => (
            <p className="body_copy" key={i}>
              {copy}
            </p>
          ))}
        </div>
      );
    }
  }
}

class LandingButtons extends React.Component {
  render() {
    const currentScreen = this.props.currentScreen;
    const setScenario = this.props.setScenario;
    const updateLastClick = this.props.updateLastClick;
    return (
      <div className="landing_buttons_wrapper">
        <div className="start_button_wrapper">
          <Link
            className="start_button"
            to="/save-1"
            onClick={() => {
              updateLastClick("Set Scenario: save");
              setScenario("save");
            }}
          >
            Save
          </Link>
          <div className="arrow-right" />
        </div>
        <div className="start_button_wrapper">
          <Link
            className="start_button"
            to="/invest-1"
            onClick={() => {
              updateLastClick("Set Scenario: invest");
              setScenario("invest");
            }}
          >
            Invest
          </Link>
          <div className="arrow-right" />
        </div>
      </div>
    );
  }
}

class ResultButtons extends React.Component {
  render() {
    const currentScreen = this.props.currentScreen;
    const updateLastClick = this.props.updateLastClick;
    const updateClickstream = this.props.updateClickstream;
    const segmentUrl = element => {
      let url = element.destination;
      let assetName = element.asset_name;
      let segmented = url.includes("/awardtype/")
        ? url.split("/awardtype/")[1]
        : url;
      if (assetName) {
        segmented = `${segmented}/${assetName}`;
      }
      return segmented;
    };
    let wrapper = (element, i) => {
      if (element.type === "offsite-link") {
        return (
          <div className="result_button_wrapper" key={i}>
            <a
              href={element.destination}
              target="_blank"
              className="result_button"
              onClick={() => {
                updateClickstream(segmentUrl(element), element.copy.join(""));
              }}
            >
              <div className={element.image + " button_image"} />
              <span className="result_button_copy">
                <span>{element.copy[0]}</span>
                <span className="result_button_orphan">{element.copy[1]}</span>
                <span className="result_button_orphan">{element.copy[2]}</span>
              </span>
            </a>
          </div>
        );
      } else if (element.type === "internal-link") {
        return (
          <div className="result_button_wrapper" key={i}>
            <Link
              to={element.destination}
              className="result_button"
              onClick={() => updateLastClick(element.copy.join(""))}
            >
              <div className={element.image + " button_image"} />
              <span className="result_button_copy">
                <span>{element.copy[0]}</span>
                <span className="result_button_orphan">{element.copy[1]}</span>
                <span className="result_button_orphan">{element.copy[2]}</span>
              </span>
            </Link>
          </div>
        );
      }
    };
    return (
      <div className="result_buttons_wrapper">
        {data[currentScreen].options.map(wrapper)}
      </div>
    );
  }
}

class ContactButtons extends React.Component {
  render() {
    const currentScreen = this.props.currentScreen;
    const updateLastClick = this.props.updateLastClick;
    const updateClickstream = this.props.updateClickstream;
    let wrapper = (element, i) => {
      if (element.type === "offsite-link") {
        return (
          <div className="result_button_wrapper contact_button" key={i}>
            <a
              href={element.destination}
              target="_blank"
              onClick={() => {
                updateClickstream(element.destination, element.copy.join(" "));
              }}
            >
              <div className={element.image} />
              <p className="button_copy">
                Schedule an
                <br />
                appointment
                <span className="sprite-caret" />
              </p>
            </a>
          </div>
        );
      } else if (element.type === "static") {
        return (
          <div className="result_button_wrapper contact_button" key={i}>
            <div className={element.image} />
            <p className="button_copy">{element.copy[0]}</p>
            <p className="button_copy">{element.copy[1]}</p>
          </div>
        );
      }
    };
    return (
      <div className="contact_buttons_wrapper">
        {data[currentScreen].options.map(wrapper)}
      </div>
    );
  }
}

class AdvanceButton extends React.Component {
  render() {
    const currentScreen = this.props.currentScreen;
    const updateLastClick = this.props.updateLastClick;
    let wrapper = (element, i) => {
      return <p key={i}>{element}</p>;
    };
    if (data[currentScreen].properties.name === "save-4") {
      return (
        <div className="advance_button_wrapper">
          <div className="disclaimer">
            {data[currentScreen].advance.disclaimer.map(wrapper)}
          </div>
          <div className="advance_button_content_wrapper">
            <Link
              to={data[currentScreen].advance.destination}
              className="advance_button"
              onClick={() => updateLastClick(data[currentScreen].advance.copy)}
            >
              Awards
              <span className="bold"> + </span> sound investing choices
              <span className="bold"> = </span>
              <span>boost to my future</span>
            </Link>
          </div>
        </div>
      );
    } else if (data[currentScreen].properties.type === "graph") {
      return (
        <div className="advance_button_wrapper">
          <div className="disclaimer">
            {data[currentScreen].advance.disclaimer.map(wrapper)}
          </div>
          <div className="advance_button_content_wrapper">
            <Link
              to={data[currentScreen].advance.destination}
              className="advance_button"
              onClick={() => updateLastClick(data[currentScreen].advance.copy)}
            >
              {data[currentScreen].advance.copy}
            </Link>
          </div>
        </div>
      );
    } else {
      return (
        <div className="advance_button_wrapper">
          <div className="advance_button_content_wrapper">
            <Link
              to={data[currentScreen].advance.destination}
              className="advance_button"
              onClick={() => updateLastClick(data[currentScreen].advance.copy)}
            >
              {data[currentScreen].advance.copy}
            </Link>
          </div>
        </div>
      );
    }
  }
}

class GraphContent extends React.Component {
  state = {
    hasBaseline: false,
    hasMidLine: false
  };

  componentDidMount() {
    const screenId = data[this.props.currentScreen].properties.name;

    if (screenId === "save-2" || screenId === "invest-1") {
      this.setState({
        hasBaseline: true
      });
    } else if (
      screenId === "save-3" ||
      screenId === "invest-2" ||
      screenId === "save-4" ||
      screenId === "invest-3"
    ) {
      this.setState({
        hasBaseline: true,
        hasMidline: true
      });
    }
  }

  render() {
    const currentScreen = this.props.currentScreen;
    const handleClick = this.props.handleClick;
    const openNode = this.props.openNode;
    const nodeIsOpen = this.props.nodeIsOpen;
    const updateNodeProgress = this.props.updateNodeProgress;
    const updateClickstream = this.props.updateClickstream;
    const updateLastClick = this.props.updateLastClick;
    const screenId = data[currentScreen].properties.name;
    const graphSize = data[currentScreen].graph.size;
    return (
      <div className={"graph_content graph_content--" + screenId}>
        <div className="graph_wrapper background-image">
          <div className={"graph_line " + graphSize}>
            <GraphNodes
              currentScreen={currentScreen}
              handleClick={handleClick}
              openNode={openNode}
              nodeIsOpen={nodeIsOpen}
              updateNodeProgress={updateNodeProgress}
              updateClickstream={updateClickstream}
              updateLastClick={updateLastClick}
            />
            <GraphLabels currentScreen={currentScreen} />
            <div className="graph_line_wrapper">
              {this.state.hasMidline && <div className="graph-curve" />}
              {this.state.hasBaseline && <div className="graph-straight" />}
            </div>
          </div>
        </div>
        <TimeScale />
      </div>
    );
  }
}

class GraphNodes extends React.Component {
  render() {
    const currentScreen = this.props.currentScreen;
    const openNode = this.props.openNode;
    const nodeIsOpen = this.props.nodeIsOpen;
    const updateNodeProgress = this.props.updateNodeProgress;
    const updateClickstream = this.props.updateClickstream;
    const updateLastClick = this.props.updateLastClick;
    let wrapper = (element, i) => {
      if (nodeIsOpen === i) {
        return (
          <Node
            key={i}
            active={true}
            element={element}
            nodeIsOpen={nodeIsOpen}
            currentScreen={currentScreen}
            updateNodeProgress={updateNodeProgress}
            updateClickstream={updateClickstream}
            updateLastClick={updateLastClick}
          />
        );
      } else {
        return (
          <Node
            key={i}
            active={false}
            element={element}
            nodeIsOpen={nodeIsOpen}
            currentScreen={currentScreen}
            updateNodeProgress={updateNodeProgress}
            onClick={e => {
              let scenario = data[currentScreen].properties.name.split("-")[0];
              scenario = scenario.charAt(0).toUpperCase() + scenario.slice(1);
              let nodeName = `${scenario}: ${data[currentScreen].title.join(
                " "
              )} PopUp: ${element.copy[0]}`;
              openNode(i);
              updateNodeProgress(data[currentScreen].properties.name, i);
              updateClickstream(null, nodeName);
            }}
          />
        );
      }
    };
    return (
      <div className="graph_nodes">
        {data[currentScreen].options.map(wrapper)}
      </div>
    );
  }
}

class GraphLabels extends React.Component {
  getContent = () => {
    switch (data[this.props.currentScreen].properties.name) {
      case "save-1":
        return (
          <div
            className={
              "graph_labels " + data[this.props.currentScreen].properties.name
            }
          >
            <img
              className="start_value save_1_start"
              src={chart12kBlackPNG}
              srcSet={chart12kBlackSVG}
              alt={data[this.props.currentScreen].graph.start}
            />
            <img
              className="end_value save_1_end"
              src={chart12kBlackPNG}
              srcSet={chart12kBlackSVG}
              alt={data[this.props.currentScreen].graph.end}
            />
          </div>
        );
      case "invest-1":
      case "save-2":
        return (
          <div
            className={
              "graph_labels " + data[this.props.currentScreen].properties.name
            }
          >
            <img
              className="start_value save_2_start"
              src={chart12kBlackPNG}
              srcSet={chart12kBlackSVG}
              alt={data[this.props.currentScreen].graph.start}
            />
            <img
              className="graph_notes"
              src={chartIfYouInvestPNG}
              srcSet={chartIfYouInvestSVG}
              alt={data[this.props.currentScreen].note}
            />
            <div className="y_axis_wrapper">
              <div className="end_wrapper">
                <img
                  className="end_value save_2_end"
                  src={chart54kBlackPNG}
                  srcSet={chart54kBlackSVG}
                  alt={data[this.props.currentScreen].graph.end}
                />
              </div>
              <div className="graph_impact_wrapper save_2_impact">
                <img
                  className="graph_impact"
                  src={chart42kPNG}
                  srcSet={chart42kSVG}
                  alt={data[this.props.currentScreen].graph.impact}
                />
              </div>
              <div className="baseline_wrapper">
                <img
                  className="end_value save_2_baseline"
                  src={chart12kPNG}
                  srcSet={chart12kSVG}
                  alt={data[this.props.currentScreen].graph.baseline[0]}
                />
              </div>
            </div>
          </div>
        );
      case "invest-2":
      case "save-3":
        return (
          <div
            className={
              "graph_labels " + data[this.props.currentScreen].properties.name
            }
          >
            <img
              className="start_value save_3_start"
              src={chart24kBlackPNG}
              srcSet={chart24kBlackSVG}
              alt={data[this.props.currentScreen].graph.start}
            />
            <span className="sprite-start" />
            <img
              className="graph_notes"
              src={chartAddingAnotherPNG}
              srcSet={chartAddingAnotherSVG}
              alt={data[this.props.currentScreen].note}
            />
            <div className="y_axis_wrapper">
              <div className="end_wrapper">
                <img
                  className="end_value save_3_end"
                  src={chart105kPNG}
                  srcSet={chart105kSVG}
                  alt={data[this.props.currentScreen].graph.end}
                />
              </div>
              <div className="graph_impact_wrapper save_3_impact">
                <img
                  className="graph_impact"
                  src={chart81kPNG}
                  srcSet={chart81kSVG}
                  alt={data[this.props.currentScreen].graph.impact}
                />
              </div>
              <div className="midline_wrapper">
                <img
                  className="end_value save_3_midline"
                  src={chart54kPNG}
                  srcSet={chart54kSVG}
                  alt={data[this.props.currentScreen].graph.baseline[0]}
                />
              </div>
              <div className="baseline_wrapper">
                <img
                  className="end_value save_3_baseline"
                  src={chart12kPNG}
                  srcSet={chart12kSVG}
                  alt={data[this.props.currentScreen].graph.baseline[1]}
                />
              </div>
            </div>
          </div>
        );
      case "save-4":
      case "invest-3":
        return (
          <div
            className={
              "graph_labels " + data[this.props.currentScreen].properties.name
            }
          >
            <img
              className="start_value save_4_start"
              src={chart24kBlackPNG}
              srcSet={chart24kBlackSVG}
              alt={data[this.props.currentScreen].graph.start}
            />
            <img
              className="graph_notes"
              src={chartAddingAnotherPNG}
              srcSet={chartAddingAnotherSVG}
              alt={data[this.props.currentScreen].note}
            />
            <div className="y_axis_wrapper">
              <div className="end_wrapper">
                <img
                  className="end_value save_4_end"
                  src={chart105kPNG}
                  srcSet={chart105kSVG}
                  alt={data[this.props.currentScreen].graph.end}
                />
              </div>
              <div className="graph_impact_wrapper save_4_impact">
                <img
                  className="graph_impact"
                  src={chart81kPNG}
                  srcSet={chart81kSVG}
                  alt={data[this.props.currentScreen].graph.impact}
                />
              </div>
              <div className="midline_wrapper">
                <img
                  className="end_value save_4_midline"
                  src={chart54kPNG}
                  srcSet={chart54kSVG}
                  alt={data[this.props.currentScreen].graph.baseline[0]}
                />
              </div>
              <div className="baseline_wrapper">
                <img
                  className="end_value save_4_baseline"
                  src={chart12kPNG}
                  srcSet={chart12kSVG}
                  alt={data[this.props.currentScreen].graph.baseline[1]}
                />
              </div>
            </div>
          </div>
        );
      default:
        break;
    }
  };

  render() {
    return this.getContent();
  }
}

class Node extends React.Component {
  render() {
    const element = this.props.element;
    const nodeLeftPosition = element.position[0];
    const nodeBottomPosition = element.position[1];
    const onClickFunction = this.props.onClick;
    const currentScreen = this.props.currentScreen;
    const updateNodeProgress = this.props.updateNodeProgress;
    const updateClickstream = this.props.updateClickstream;
    const updateLastClick = this.props.updateLastClick;
    const reverseOrder = () => {
      if (data[currentScreen].properties.name === "save-3") {
        return true;
      } else if (data[currentScreen].properties.name === "save-4") {
        return true;
      } else if (data[currentScreen].properties.name === "invest-3") {
        return true;
      } else if (data[currentScreen].properties.name === "invest-2") {
        return true;
      } else {
        return false;
      }
    };
    const segmentUrl = element => {
      let url = element.destination;
      let assetName = element.asset_name;
      let segmented = url.includes("/awardtype/")
        ? url.split("/awardtype/")[1]
        : url;
      if (assetName) {
        segmented = `${segmented}/${assetName}`;
      }
      return segmented;
    };
    return (
      <div
        className={`node ${reverseOrder() ? "reverse_order" : ""}`}
        style={{
          left: nodeLeftPosition + "%",
          bottom: nodeBottomPosition + "vw"
        }}
      >
        <a
          className={`node_content ${
            this.props.active ? "node_content--active" : ""
          }`}
          href={element.destination}
          target="_blank"
          onClick={() => {
            updateLastClick(element.destination);
            updateClickstream(segmentUrl(element), element.copy[1], true);
            updateNodeProgress(
              data[currentScreen].properties.name,
              element.destination
            );
          }}
        >
          <p className="node_copy">{element.copy[0]}</p>
          <p className="node_link">
            {element.copy[1]}
            <span className="sprite-caret" />
          </p>
          <div className="node_arrow" />
        </a>
        <div className="node_trigger" onClick={onClickFunction} />
        <div
          className={`animation_circle ${
            this.props.active ? "" : "animation_active"
          }`}
        />
      </div>
    );
  }
}

class TimeScale extends React.Component {
  render() {
    return (
      <ul className="time-scale">
        <li className="time-scale__value time-scale__value--0-5">
          <span className="time-scale__marker">0 years</span>
        </li>
        <li className="time-scale__value time-scale__value--5-10">
          <span className="time-scale__marker" />
        </li>
        <li className="time-scale__value time-scale__value--10-20">
          <span className="time-scale__marker" />
        </li>
        <li className="time-scale__value time-scale__value--20-30">
          <span className="time-scale__marker time-scale__marker--20" />
          <span className="time-scale__marker time-scale__marker--30">
            30 years
          </span>
        </li>
      </ul>
    );
  }
}

class DisplaySizeFallback extends React.Component {
  render() {
    return (
      <div className="display_size_fallback">
        <GlobalHeader updateClickstream={this.props.updateClickstream} />
        <h1>Please rotate your device.</h1>
        <p>This experience is optimized for landscape mode.</p>
        <img
          className="mobile_animation"
          src={mobileAnimation}
          alt="Please rotate your device"
        />
      </div>
    );
  }
}

ReactDOM.render(<App />, document.getElementById("root"));
